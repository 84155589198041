import React, { useRef, useEffect } from 'react';
import Chart from 'chart.js/auto';

const ROIChart = ({ data, width, height }) => {
  const canvasRef = useRef(null);
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = canvasRef.current.getContext('2d');
    let chartInstance;

    if (chartRef.current) {
      chartRef.current.destroy();
    }

    chartInstance = new Chart(ctx, {
      type: 'line',
      data: {
        labels: data.labels,
        datasets: [
          {
            label: 'ROI',
            data: data.values,
            borderColor: 'orange',
            backgroundColor: '#ffd700',
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });

    chartRef.current = chartInstance;

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [data]);

  return (
    <canvas
      ref={canvasRef}
      width={width || 200}
      height={height || 200}
    ></canvas>
  );
};

export default ROIChart;
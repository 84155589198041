import React, { useRef, useEffect } from 'react';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
ChartJS.register(ArcElement, Tooltip, Legend);

const ProfitPieChart = ({ data, width, height }) => {
  const canvasRef = useRef(null);

  useEffect(() => {

    const chart = new ChartJS(canvasRef.current, {
      type: 'pie',
      data: {
        labels: data.labels,
        datasets: [
          {
            label: 'Cash Amount',
            data: data.values,
            backgroundColor: ['rgba(255, 204, 0, 0.2)', 'rgba(255, 187, 51, 0.2)'],
            borderColor: ['rgba(255, 204, 0, 1)', 'rgba(255, 187, 51, 1)'],
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          y: {
            grid: {
              display: false,
            },
          },
          x: {
            grid: {
              display: false,
            },
          },
        },
      },
    });

    return () => {
      chart.destroy();
    };
  }
  , [data]);

  return (
    <canvas
      ref={canvasRef}
      width={width || 200}
      height={height || 200}
    ></canvas>
  );
};


export default ProfitPieChart;
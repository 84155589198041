import React, { useState } from 'react';
import ROIChart from './components/ROIChart';
import PriceJourneyChart from './components/PriceJourneyChart';
import './App.css';
import { ReactComponent as Logo } from './lex_logo.svg';
import { CSVLink } from 'react-csv';

import { Layout, Table, Row, Col, Form, InputNumber, Button } from 'antd';

import ProfitPieChart from './components/ProfitPieChart';

const { Content } = Layout;

function formatCurrency(value) {

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
}

const summaryColumns = [
  {
    title: 'No. Artworks',
    dataIndex: 'period',
    key: 'period',
  },
  {
    title: 'Yield %',
    dataIndex: 'Yn',
    key: 'Yn',
    render: (value) => `${value}%`,
  },
  {
    title: 'Total Sales Volume',
    dataIndex: 'CSn',
    key: 'CSn',
    render: (value) => formatCurrency(value),
  },
  {
    title: 'Sales Needed to Recoup Investment',
    dataIndex: 'PBn',
    key: 'PBn',
  },
  {
    title: 'Total Compensation Paid to Buyers',
    dataIndex: 'cumulativeYield',
    key: 'cumulativeYield',
    render: (value) => formatCurrency(value),
  },
  {
    title: 'Total LEX Profit',
    dataIndex: 'LEXPn',
    key: 'LEXPn',
    render: (value) => formatCurrency(value),
  },
  {
    title: 'Floor Price at End of Collection',
    dataIndex: 'unrealizedValueOfArtwork',
    key: 'unrealizedValueOfArtwork',
    render: (value) => formatCurrency(value),

  },
  {
    title: 'LEX Market Cap',
    dataIndex: 'MCn',
    key: 'MCn',
    render: (value) => formatCurrency(value),
  },
];

const columns = [
  {
    title: 'Period',
    dataIndex: 'period',
    key: 'period',
  },
  {
    title: 'Sale Price',
    dataIndex: 'SPn',
    key: 'SPn',
    render: (value) => formatCurrency(value),
  },
  {
    title: 'Yield $',
    dataIndex: 'Y$n',
    key: 'Y$n',
    render: (value) => formatCurrency(value),
  },
  {
    title: 'Total Asset Value',
    dataIndex: 'TAn',
    key: 'TAn',
    render: (value) => formatCurrency(value),
  },
  {
    title: 'Cumulative Buyer Yield',
    dataIndex: 'BYn',
    key: 'BYn',
    render: (value) => formatCurrency(value),
  },
  {
    title: 'LEX Profit $',
    dataIndex: 'LEXPn',
    key: 'LEXPn',
    render: (value) => formatCurrency(value),
  },
  {
    title: 'LEX Market Cap',
    dataIndex: 'MCn',
    key: 'MCn',
    render: (value) => formatCurrency(value),
  },
];

const App = () => {
  const [results, setResults] = useState([]);
  const [summary, setSummary] = useState({});

  const [password, setPassword] = useState("");
  const [accessGranted, setAccessGranted] = useState(false);

  const handlePasswordSubmit = (event) => {
    event.preventDefault();

    if (password === "notasecurity") {
      setAccessGranted(true);
    } else {
      alert("Wrong password");
    }
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };


  function calculatePeriodsNeeded(y, s, a, goal, currentPeriod) {
    let n = currentPeriod;
    let t = 0;
    while (t < goal) {
      n++;
      t = 0;
      for (let i = currentPeriod; i <= n; i++) {
        t += y * s * Math.pow(1 + a, i - currentPeriod);
      }
    }
    return n - currentPeriod;
  }

  function calculateTotalCompensation(y, s, periods, a, currentPeriod) {
    let t = 0;
    for (let i = currentPeriod; i <= periods; i++) {
      t += y * s * Math.pow(1 + a, i - currentPeriod);
    }
    return t;
  }

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  // make all charts zero by default
  const [roiChartData, setRoiChartData] = useState({
    labels: [],
    values: [],
  });

  const [profitPieChartData, setprofitPieChartData] = useState({
    labels: [],
    values: [],
  });

  const [priceJourneyChartData, setPriceJourneyChartData] = useState({
    labels: [],
    values: [],
  });


  // DONE
  const updateROIChartData = (artworkSales) => {
    if (!artworkSales || artworkSales.length === 0) {
      return { labels: [], values: [] };
    }

    let cumulativeYield = 0;
    const labels = artworkSales.map((sale) => `Artwork ${sale.period}`);
    const values = artworkSales.map((sale, index) => {
      // Calculate the appreciation value of the artwork in the current period
      const appreciationValue = index > 0 ? sale.SPn - artworkSales[index - 1].SPn : 0;

      // Calculate the total return for the buyer, which includes the appreciation value and the yield received
      const totalReturn = appreciationValue + sale['Y$n'];

      // Calculate the initial investment for the buyer, which is the sale price in the previous period
      const initialInvestment = index > 0 ? artworkSales[index - 1].SPn : sale.SPn;

      // Add the yield to the cumulative yield
      cumulativeYield += sale['Y$n'];

      // Calculate ROI from the buyer's point of view
      const roiValue = (cumulativeYield / initialInvestment) * 100;

      return roiValue;
    });

    return { labels, values };
  };

  const updatePriceJourneyChartData = (artworkSales) => {
    const labels = artworkSales.map((sale) => `Artwork ${sale.period}`);
    const values = artworkSales.map((sale) => sale.SPn);

    return { labels, values };
  };

  const updateProfitPieChartData = (artworkSales) => {

    // extract the total profit and total compensation paid to buyers from the last sale
    const totalProfit = artworkSales['LEXPn'];
    const totalCompensationPaidToBuyers = artworkSales['cumulativeYield'];
    const labels = ['Total LEX Profit', 'Compensation Paid to Buyers'];
    const values = [totalProfit, totalCompensationPaidToBuyers];

    return { labels, values };
  };

  const onFinish = (values) => {
    console.log('Success:', values);

    const { salePrice, yield: yieldRate, appreciationRate, periods } = values;
    const unrealizedValueOfArtwork = salePrice * (1 + appreciationRate / 100) ** (periods - 1);

    let cumulativeYield = 0;
    let SPn = salePrice;
    let CSn = 0;
    let Yn = yieldRate;
    let Y$n = 0;
    let PBn = 0;
    let Vn = 0;
    let BYn = 0;
    let TAn = 0;
    let LEXPn = 0;
    let MCn = 0;

    const newResults = [];

    for (let n = 1; n <= periods; n++) {
      if (n > 1) {
        SPn = SPn * (1 + appreciationRate / 100);
      }
      CSn = CSn + SPn;
      Y$n = SPn * Yn / 100;
      Vn = salePrice * (1 + appreciationRate / 100) ** (n - 1);

      cumulativeYield += Y$n;
      // Calculate the total compensation (BYn) for the period

      BYn = calculateTotalCompensation(Yn / 100, SPn, periods, appreciationRate / 100, n);
      PBn = calculatePeriodsNeeded(Yn / 100, SPn, appreciationRate / 100, SPn, n);
      LEXPn = (CSn * (1 - Yn / 100))
      MCn = (1 / (Yn / 100)) * SPn
      TAn = unrealizedValueOfArtwork + BYn;


      newResults.push({
        period: n,
        SPn,
        CSn,
        Yn,
        Y$n,
        PBn,
        Vn,
        BYn,
        TAn,
        LEXPn,
        MCn,
        cumulativeYield,
        unrealizedValueOfArtwork,
      });
    }

    setResults(newResults);

    setSummary(newResults[newResults.length - 1]);


    // Update chart data state variables
    setRoiChartData(updateROIChartData(newResults));
    setprofitPieChartData(updateProfitPieChartData(newResults[newResults.length - 1]));
    setPriceJourneyChartData(updatePriceJourneyChartData(newResults));
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  }


  if (!accessGranted) {
    return (
      <div style={{ textAlign: 'center', marginTop: '100px', backgroundColor: 'linear-gradient(45deg, #1a1a1a, #3b3b3b) !important' }}>

        <form onSubmit={handlePasswordSubmit} style={{ textAlign: 'center', marginTop: '100px', backgroundColor: 'linear-gradient(45deg, #1a1a1a, #3b3b3b)' }}>
          <label>
            Password:
            <input type="password" value={password} onChange={handlePasswordChange} />
          </label>
          <input type="submit" value="Submit" />
        </form>
      </div>
    );


  } else {
    return (
      <div className="gradient-bg">
        <Layout style={{ minHeight: '100vh' }}>

          <Layout>
            <div className="navbar-brand nav-container" href="#">
              <Logo className="logo" />
              <title className="title">LEX Finance</title>
            </div>
            <Content style={{ margin: '24px 16px', backgroundColor: 'linear-gradient(45deg, #1a1a1a, #3b3b3b)' }}>
              <div className="gradient-bg" style={{ padding: 24, minHeight: 360, maxWidth: 1200, justifyContent: 'center', margin: '0 auto' }}>
                <h1 style={{ textAlign: 'center' }}
                >Enter Collection Details</h1>
                <Form
                  className='form'
                  layout="inline"
                  name="basic"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  style={{ alignContent: 'center', justifyContent: 'center', margin: '0 auto' }}
                >
                  <Form.Item
                    className='form-item'
                    label="Sale Price"
                    name="salePrice"
                    rules={[
                      { required: true, message: 'Please input the sale price!' },
                    ]}
                  >
                    <InputNumber min={0} />
                  </Form.Item>

                  <Form.Item
                    className='form-item'
                    label="Yield"
                    name="yield"
                    rules={[
                      { required: true, message: 'Please input the yield!' },
                    ]}
                  >
                    <InputNumber min={0} max={100} />
                  </Form.Item>
                  <Form.Item
                    className='form-item'
                    label="Appreciation Rate"
                    name="appreciationRate"
                    rules={[
                      {
                        required: true,
                        message: 'Please input the appreciation rate!',
                      },
                    ]}
                  >
                    <InputNumber min={0} max={100} />
                  </Form.Item>
                  <Form.Item
                    className='form-item'
                    label="Periods"
                    name="periods"
                    rules={[
                      { required: true, message: 'Please input the periods!' },
                    ]}
                  >
                    <InputNumber min={0} />
                  </Form.Item>
                  <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
                <h1>Summary</h1>
                <Table columns={summaryColumns} dataSource={[summary]} rowKey="period" className='table' />
                <h1>Results</h1>
                <Table columns={columns} dataSource={results} rowKey="period" className='table' />
              </div>

              <CSVLink
                data={results}
                filename={"lex-finance-data.csv"}
                className="btn btn-primary"
                target="_blank"
              >
                Download CSV
              </CSVLink>


              {/* CHARTS HERE */}
              <div className="site-layout-background" style={{ padding: 24, minHeight: 360, maxWidth: 1200, justifyContent: 'center', margin: '0 auto' }}>
                <Row gutter={[16, 16]}>

                  {/* ROI CHART HERE */}
                  <Col span={8}>
                    <h2
                      style={{ textAlign: 'center' }}
                    >ROI Across Sales Periods</h2>
                    <ROIChart data={roiChartData} />
                  </Col>

                  {/* PIE CHART HERE */}
                  <Col span={8}>
                    <h2
                      style={{ textAlign: 'center' }}
                    >Sales Distribution</h2>
                    {/* CREATE profitPieChartData */}
                    <ProfitPieChart data={profitPieChartData} />
                  </Col>

                  {/* ANOTHER MEANINGFUL CHART HERE */}
                  <Col span={8}>
                    <h2
                      style={{ textAlign: 'center' }}
                    >Price Journey</h2>
                    <PriceJourneyChart data={priceJourneyChartData} />
                  </Col>

                </Row>
              </div>
            </Content>
          </Layout>
        </Layout>
      </div>
    );
  }
}

export default App;


// In a separate file, e.g. PriceJourneyChart.js
import React, { useRef, useEffect } from 'react';
import Chart from 'chart.js/auto';


const PriceJourneyChart = ({ data }) => {
const canvasRef = useRef(null);

  useEffect(() => {
    const ctx = canvasRef.current.getContext('2d');
    const chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: data.labels,
        datasets: [
          {
            label: 'Price',
            data: data.values,
            borderColor: 'orange',
            backgroundColor: '#ffd700',
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });

    return () => {
      chart.destroy();
    };
  }, [data]);

  return (
    <canvas
      ref={canvasRef}
      width={200}
      height={200}
    ></canvas>
  );
}

export default PriceJourneyChart;

